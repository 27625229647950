@charset "UTF-8";

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Outfit', sans-serif;
    }
    body {
        overflow-x: hidden;
        scroll-behavior: smooth;
    }
}

@layer components {
    .container {
        @apply lg:max-w-[1536px] md:max-w-[1200px] sm:max-w-[750px];
      }      
}

/* Buttons (Start) */
.btn-primary-sm {
  @apply bg-ascent px-5 py-2 text-xs font-medium text-black border-2 border-ascent hover:bg-black hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out
}
.btn-primary-md {
  @apply bg-ascent px-6 py-3 text-sm font-medium text-black border-2 border-ascent hover:bg-black hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out
}
.btn-primary-lg {
  @apply bg-ascent px-7 py-4 text-base font-medium text-black border-2 border-ascent hover:bg-black hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out
}
/* Buttons (End) */

/* Inputs (Start) */
.input-group {
  @apply flex flex-col
}

.input-box-sm {
  @apply px-3 py-2 border text-xs outline-ascent font-medium bg-white
}

.input-box-md {
  @apply px-4 py-3 border text-sm outline-ascent font-medium bg-white
}

.input-box-lg {
  @apply px-5 py-4 border text-base outline-ascent font-medium bg-white
}

.input-box-sm.input-invalid,
.input-box-md.input-invalid,
.input-box-lg.input-invalid {
  @apply border-red-600
}

.input-label {
  @apply text-xs font-medium text-slate-800 mb-2 text-left
}

.input-error {
  @apply text-xs font-medium text-red-600 mt-2 text-left
}

.input-box-sm.tagify,
.input-box-md.tagify,
.input-box-lg.tagify {
  @apply p-[0.3rem]
}

.tagify.tagify--focus {
  @apply ring-[1.5px] ring-ascent
}

.input-radio {
  @apply flex items-center space-x-1.5
}

.input-radio input[type=radio] {
  @apply w-4 h-4 text-ascent cursor-pointer
}

input[type=file] {
  @apply pt-[0.58rem] pb-[0.58rem]
}

.input-radio label {
  @apply font-medium text-slate-800 text-xs cursor-pointer
}

input:not(.peer)[type=checkbox] {
  @apply appearance-none h-4 w-4 border border-gray-200 rounded bg-white checked:border-ascent checked:bg-ascent focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer text-white
}

input:not(.peer)[type=checkbox]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

input:read-only {
  @apply outline-none cursor-default
}

.input-box-dragable {
  @apply relative h-[200px] flex flex-col space-y-3 justify-center items-center w-full border border-slate-300 border-dashed rounded-lg text-slate-600 font-medium text-xs
}

.input-box-dragable input {
  @apply absolute h-[200px] w-full cursor-pointer opacity-0
}

.input-thumbnail-preview {
  @apply h-[200px] w-max rounded-lg border-slate-200 border
}

.input-label em {
  @apply text-red-500 font-medium text-xs not-italic
}

.input-label span {
  @apply text-gray-500 font-medium text-xs
}

input[type='date'].input-box-md {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}

select.input-box-md {
  appearance: none;
  background: white;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M6.1018 8C5.02785 8 4.45387 9.2649 5.16108 10.0731L10.6829 16.3838C11.3801 17.1806 12.6197 17.1806 13.3169 16.3838L18.8388 10.0731C19.5459 9.2649 18.972 8 17.898 8H6.1018Z' fill='%23212121'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
}
.toggler-switch {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 20px;
}
.toggler-switch > input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .2s;
  transition: .2s;
}  
.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .2s;
  transition: .2s;
}  
input:checked + .slider {
  @apply bg-ascent
}
input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
.slider {
  border-radius: 12px;
}
.slider:before {
  border-radius: 9px;
}

/* Inputs (End) */
 

/* Header (Start) */
header.active {
  background-color: #000000ef;
  transition: 0.7s ease;
}
header.inactive {
  background-color: transparent;
  transition: 0.7s ease;
}
.header-nav-link {
  @apply text-white text-sm tracking-wider hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out
}
.sidebar-toggler-button {

}
/* Header (End) */

 /* Spinner  */
 @keyframes spinner {
  to { transform: rotate(360deg); }
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #dbd834;
  border-radius: 50%;
  animation: spinner 0.6s linear infinite;
  margin: 0 auto; /* Center the spinner */
}
/* Sidebar (Start) */
aside.sidebar {
  @apply h-screen bg-white fixed top-0 z-40 w-full overscroll-auto
}
aside.sidebar.active {
  left: 0%;
  transition: 0.3s ease;
}
aside.sidebar.inactive {
  left: -100%;
  transition: 0.3s ease;
}
/* Sidebar (End) */


/* Home Page (Start) */
.slide-shapes .shape-1 {
  -webkit-clip-path: polygon(48% 0, 89% 0, 40% 100%, 0% 100%);
clip-path: polygon(48% 0, 89% 0, 40% 100%, 0% 100%);
}
.slide-shapes .shape-2 {
clip-path: polygon(40% 0%, 100% 0%, 59% 100%, 0% 100%);
}
.slide-shapes .shape-1 {
  @apply bg-ascent bg-opacity-50 h-screen w-[50vw]
}
.slide-shapes .shape-2 {
  @apply bg-ascent h-[50vh] w-[30vw] mt-[100px]
}
.feature-card .shaped-bg {
  -webkit-clip-path: polygon(50% 0, 100% 0, 50% 100%, 0% 100%);
clip-path: polygon(40% 0, 100% 0, 60% 100%, 0% 100%);
}
.feature-card .icon-shape {
  -webkit-clip-path: polygon(50% 0, 100% 0, 50% 100%, 0% 100%);
clip-path: polygon(22% 0, 100% 0, 77% 100%, 0% 100%);
}

.feature-card .icon-shape > div {
  -webkit-clip-path: polygon(50% 0, 100% 0, 50% 100%, 0% 100%);
  clip-path: polygon(22% 0, 100% 0, 77% 100%, 0% 100%);
}
.portfolio-slider .swiper-wrapper {
  @apply overflow-visible
}
.testimonial-slider .swiper-pagination {
  @apply !text-left
}
/* Home Page (End */

/* Dashboard (Start) */
.dashboard-tab {
  @apply py-2.5 px-4 bg-gray-100 w-full flex items-center justify-start space-x-2
}
.dashboard-tab.active {
  @apply bg-ascent
}
.dashboard-tab span {
  @apply font-medium
}
/* Dashboard (End) */

/* Swal (Start) */
.swal2-modal {
  @apply border shadow-lg rounded-none pb-12
}
.swal2-title {
  @apply text-black font-semibold text-2xl
}
.swal2-html-container {
  @apply !text-sm text-gray-500
}
.swal2-confirm {
  @apply !border-2 !border-ascent !shadow-none !bg-ascent !text-black !px-6 !py-3 !text-sm !font-medium !rounded-none hover:!bg-black hover:!text-ascent
}
.swal2-cancel {
  @apply !border-2 !border-gray-500 !shadow-none !bg-gray-100 !text-black !px-6 !py-3 !text-sm !font-medium !rounded-none hover:!bg-black hover:!text-ascent
}

article.policies {
  @apply space-y-5
}
article.policies h1 {
  @apply text-lg font-semibold
}
article.policies ul {
  @apply list-disc pl-4
}

details summary::marker {
  content: '';
}

.goog-te-combo {
  @apply p-2
}